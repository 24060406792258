import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv';
import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { dosisPorCampo } from './PreProcess'






export default function AnalycTable({data, machinery = false}){
    //console.log('data: ', data)

    let dataFinal = dosisPorCampo(data)

    const headers = [
        { label: machinery ? 'Maquinaria' : 'Insumo', key: 'insumo' },
      ];

    let columns = [
        {
            name: machinery ? 'Maquinaria': 'Insumo',
            selector: row => row.insumo,
        },
        
    ];

    dataFinal.headers.forEach((element) => {
        let head = {
            name: element,
            selector: row => row[element],
        }
        let csvHead = { label: element, key: element}
        columns.push(head)
        headers.push(csvHead)
    }); 

    

    console.log('final data: ',dataFinal)

    return(
        <div>
            <Box marginTop='25px'>
                <Button color='primary' variant='contained'>
                    <CSVLink data={dataFinal.data} headers={headers} style={{color: 'white', textDecoration: 'none'}}>
                    csv
                    </CSVLink>
                </Button>
            </Box>
            <DataTable
                columns={columns}
                data={dataFinal.data}
                pagination
                paginationComponent={CustomMaterialPagination}
            />
        </div>
    )
}



function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
    const handleFirstPageButtonClick = () => {
        onChangePage(1);
    };

    // RDT uses page index starting at 1, MUI starts at 0
    // i.e. page prop will be off by one here

    const getNumberOfPages = (count, rowsPerPage) => Math.max(0, Math.ceil(count / rowsPerPage))

    const handleBackButtonClick = () => {
        onChangePage(page);
    };

    const handleNextButtonClick = () => {
        onChangePage(page + 2);
    };

    const handleLastPageButtonClick = () => {
        onChangePage(getNumberOfPages(count, rowsPerPage));
    };

    return (
        <>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='first page'>
                <FirstPageIcon />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
                aria-label='next page'
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
                aria-label='last page'
            >
                <LastPageIcon />
            </IconButton>
        </>
    );
}

const CustomMaterialPagination = ({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage }) => (
    <TablePagination
        component='nav'
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={currentPage - 1}
        onChangePage={onChangePage}
        onChangeRowsPerPage={({ target }) => onChangeRowsPerPage(Number(target.value))}
        ActionsComponent={TablePaginationActions}
    />
);


