import React from 'react'
import { Switch } from 'react-router-dom'
import Template from 'components/Template'

import Route from 'components/CustomRoute'

import ResultState from 'components/ResultState'
import UnderConstruction from 'components/UnderConstruction'
import MonthlyStateResult from 'components/MonthlyStateResult'
import Spends from 'components/Spends'
import Incomes from 'components/Incomes'
import MachineriesResources from 'components/MachineriesResources'
import ManagementTemplates from 'components/ManagementTemplates'
import ManagementCalendar from 'components/ManagementCalendar'
import CustomPrivateRoute from 'components/CustomPrivateRoute'
import UsersAdmin from 'components/UsersAdmin'
import Farms from 'components/Farms'
import ForageBalance from 'components/ForageBalance'
import Analytics from './Analytics/Analytics'

import Login from 'components/Login'
import MeadowsManager from 'components/MeadowsManager'
import Businesses from 'components/Businesses'
import ServiceProviders from 'components/ServiceProviders'
import FarmOwners from 'components/FarmOwners'

import CattleSummaries from 'components/CattleSummaries'
import ProductiveUnit from 'components/ProductiveUnit'

import Home from 'components/Home'
import Dashboard from 'components/Dashboard'

function Routes(props) {
  return (
    <Switch>
      <Route exact path='/iniciar-sesion' component={Login} />

      <Template>
        <Switch>
          <CustomPrivateRoute
            exact
            component={Spends}
            path='/:farmOwnerId/:businessId/finanzas/compras/:year'
          />
          <CustomPrivateRoute
            exact
            component={Incomes}
            path='/:farmOwnerId/:businessId/finanzas/ventas/:year'
          />
          <CustomPrivateRoute
            exact
            component={ResultState}
            path='/:farmOwnerId/:businessId/finanzas/estado-resultado/:year'
          />
          <CustomPrivateRoute
            exact
            component={MonthlyStateResult}
            path='/:farmOwnerId/:businessId/finanzas/estado-resultado-mensual/:year'
          />
          <CustomPrivateRoute
            exact
            component={MachineriesResources}
            path='/planificacion/base-de-datos'
          />
          <CustomPrivateRoute
            exact
            component={ManagementTemplates}
            path='/:farmOwnerId/:farmId/planificacion/plantillas-manejos'
          />
          <CustomPrivateRoute
            exact
            component={ManagementCalendar}
            path='/:farmOwnerId/:farmId/planificacion/calendario-manejos/:year'
          />
          <CustomPrivateRoute
            exact
            component={CattleSummaries}
            path='/:farmOwnerId/:farmId/planificacion/visitas-prediales/:year'
          />
          <CustomPrivateRoute
            exact
            component={Analytics}
            path='/analitica'
          />
          <CustomPrivateRoute
            exact
            component={ForageBalance}
            path='/:farmOwnerId/:farmId/balance-forrajero'
          />
          <CustomPrivateRoute
            exact
            component={MeadowsManager}
            path='/:farmOwnerId/:farmId/mapas/datos-geograficos'
          />
          <CustomPrivateRoute
            exact
            component={UsersAdmin}
            path='/:farmOwnerId/administracion-usuarios'
          />
          <CustomPrivateRoute
            exact
            component={Farms}
            path='/:farmOwnerId/administracion-campos'
          />
          <CustomPrivateRoute
            exact
            component={ProductiveUnit}
            path='/:farmOwnerId/unidades-productivas'
          />
          <CustomPrivateRoute
            exact
            component={Businesses}
            path='/:farmOwnerId/administracion-empresas'
          />
          <CustomPrivateRoute
            exact
            component={ServiceProviders}
            path='/:farmOwnerId/administracion-proveedores'
          />
          <CustomPrivateRoute
            exact
            component={FarmOwners}
            path='/administracion-cuentas'
          />
          <CustomPrivateRoute
            exact
            component={Dashboard}
            path='/:farmOwnerId/:farmId/dashboard/:year'
          />
          <CustomPrivateRoute exact component={Home} path='/' />

          <CustomPrivateRoute path='*' component={UnderConstruction} />
        </Switch>
      </Template>
    </Switch>
  )
}

export default Routes
