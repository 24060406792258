import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/farms/:farmId/meadows/:templateMeadowId/soil_management/managements`

export const fields = [{ name: 'Nombre', field: 'name' }]

export const all = () => axios.get(RESOURCE_LOCATION).then(dataGetter)

export const create = (soil_management_category_id, management) =>
  axios
    .post(RESOURCE_LOCATION, {
      management: {
        ...management,
        soil_management_category_id,
        start_date: new Date(),
      },
    })
    .then(dataGetter)

export const remove = (_soil_management_category_id, management_id) =>
  axios.delete(`${RESOURCE_LOCATION}/${management_id}`).then(dataGetter)

export const edit = (management_id, management) =>
  axios.put(`${RESOURCE_LOCATION}/${management_id}`, { management }).then(dataGetter)
