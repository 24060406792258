import React,{useEffect, useState} from 'react' 
import Graph from './Graph'
import { makeStyles, Typography, Box } from '@material-ui/core/'
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import AnalycTable from './Table';
import es from 'date-fns/locale/es';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({

    root: {
        padding: '30px',
        //border: '1px solid rgba(180, 180, 180, 0.2)',
    },
    header: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    icons: {
        display: 'flex',
        alignItems: 'center',
        width: 'max-content',
    },
    paper: {
        position: 'absolute',
        top: '10%',
        left: '2.5%',
        width: '95%',
        height: '80%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
        overflowY: 'auto',
      },
    
}))

const locale = es; 

export default function GraphCards({data, title,valueType, leftLegend, bottomLegend, state, machinery = false}) {
   
    const [graphType, setGraphType] = useState('sum')  
    const [modalOpen, isModalOpen ] = useState(false)

    const classes = useStyles()

   
    

    //console.log('data: ', data)
   
    return(
        <Paper elevation={1} className={classes.root}>
            <div className={classes.header}>
                <div>
                <Typography variant='h3' color='primary' >{title}</Typography>
                <Typography>{`${format(state[0].startDate, 'd MMM YYY',{locale})} - ${format(state[0].endDate, 'd MMM YYY',{locale})}`}</Typography>
                <Typography variant='body2'>{valueType}</Typography>
                </div>
                <GraphSelector graphType={graphType} setGraphType={setGraphType} isModalOpen={isModalOpen} modalOpen={modalOpen} />
            </div>
            {graphType !== 'table' && <Graph
                machinery={machinery} 
                data={data} 
                graphType={graphType} 
                leftLegend={leftLegend} 
                bottomLegend={bottomLegend} 
                />}
            {graphType === 'table' && <AnalycTable data={data} machinery={machinery} /> }
            {<Modal
                aria-labelledby={`Graph-full-size-${title}`}
                aria-describedby="simple-modal-description"
                open={modalOpen}
                onClose={() => isModalOpen(false)}
                >
                    <div className={classes.paper}>
                        <div className={classes.header}>
                            <div>
                            <Typography variant='h3' color='primary' >{title}</Typography>
                            <Typography>{`${format(state[0].startDate, 'd MMM YYY',{locale})} - ${format(state[0].endDate, 'd MMM YYY',{locale})}`}</Typography>
                            <Typography variant='body2'>{valueType}</Typography>
                            </div>
                            <GraphSelector 
                                graphType={graphType} 
                                setGraphType={setGraphType} 
                                modalOpen={modalOpen} 
                                isModalOpen={isModalOpen}
                                />
                        </div>
                        {graphType !== 'table' && <Graph 
                            machinery={machinery} 
                            data={data} 
                            graphType={graphType} 
                            leftLegend={leftLegend} 
                            bottomLegend={bottomLegend}
                            height='85%'
                            large={true} 
                            /> }
                         {graphType === 'table' && <AnalycTable data={data} machinery={machinery} /> }
                    </div>
             </Modal>}
        </Paper>
    )
}



function GraphSelector({graphType, setGraphType, isModalOpen, modalOpen }){
    const classes = useStyles()
    
    return(
        <Paper elevation={1} className={classes.icons}>
            {graphsData.map((data, i)=>(
                <div key={`graphic-style-selector-${i}`} >
                    <IconButton 
                        onClick={()=>setGraphType(data.id)}
                        color={graphType === data.id ? 'primary' : 'default'} 
                        className={classes.iconButton} 
                        aria-label={data.label}
                        disabled={data.disabled}
                        >
                        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'>
                            <path fill={data.fill} stroke={data.stroke} stroke-width={data.strokeWidth} d={data.path}/>
                        </svg>
                    </IconButton>
                </div>
            ))}
            {modalOpen ? 
                <IconButton 
                    onClick={() => isModalOpen(false)}
                    color='default' 
                    className={classes.iconButton} 
                    aria-label='Minimise-graph-button'
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                        <path fill="currentColor" d="M4 18v2h6.586L2 28.582L3.414 30L12 21.414V28h2V18H4zM30 3.416L28.592 2L20 10.586V4h-2v10h10v-2h-6.586L30 3.416z"/>
                    </svg>
                </IconButton>
            :
                <IconButton 
                    onClick={() => isModalOpen(true)}
                    color='default' 
                    className={classes.iconButton} 
                    aria-label='Expand-graph-button'
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" stroke-width="2" d="m10 14l-8 8m-1-7v8h8M22 2l-8 8m1-9h8v8"/>
                    </svg>
                </IconButton>
            }
        </Paper>
    )
}

const graphsData = [
    { 
        path:   'M1.586 3H21v2H6.414l7 7l-7 7H21v2H1.586l9-9l-9-9Z',
        label: 'sum-view',
        fill: 'currentColor',
        stroke: 'currentColor',
        strokeWidth: '0',
        id: 'sum',
        disabled: false, 
    },
    { 
        path: 'M22 21H2V3h2v16h2v-9h4v9h2V6h4v13h2v-5h4v7Z',
        label: 'grouped-view',
        fill: 'currentColor',
        stroke: 'currentColor',
        strokeWidth: '0',
        id: 'grouped',
        disabled: false,
    },
    { 
        path: 'M22 21H2V3h2v16h2v-2h4v2h2v-3h4v3h2v-2h4v4m-4-7h4v2h-4v-2m-6-8h4v3h-4V6m4 9h-4v-5h4v5M6 10h4v2H6v-2m4 6H6v-3h4v3Z',
        label: 'stack-view',
        fill: 'currentColor',
        stroke: 'currentColor',
        strokeWidth: '0',
        id: 'stack',
        disabled: false,
    },
    { 
        path: 'M8 5v18m8-18v18M1 11h22M1 5h22M1 17h22M1 1h22v22H1V1Z', 
        label: 'table-view',
        fill: 'none',
        stroke: 'currentColor',
        strokeWidth: '2',
        id: 'table',
        disabled: false,
    },
]