import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/productive_units/`

export const ProductiveUnits = [
    { name: 'Unidad Productiva', field: 'productive_unit_name', only: 'create', value: '' },
]

export const fields = [
    ...ProductiveUnits,
]

export const all = () => axios.get(RESOURCE_LOCATION)
    .then(response => {
        const data = response.data
        return data
    })
    .catch(error => {
        console.error('Mal', error)
        throw error
    })

export const create = async (data) => {
    console.log('Data sent to API:', data); // Agregar este console.log para verificar el objeto de datos enviado a la API
    try {
        const response = await axios.post(RESOURCE_LOCATION, data)
        console.log('Response from API:', response.data) // Agregar este console.log para verificar la respuesta de la API
        return response.data
    } catch (error) {
        console.error('Error creating data via API:', error)
        throw error
    }
}

export const remove = (id) =>
    axios.delete(`${RESOURCE_LOCATION}/${id}`).then(dataGetter)