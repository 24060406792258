import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'

import ResourcesModalForm from './ResourcesModalForm'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  dropdownButton: {
    width: 5,
  },
  resourceName: {
    textTransform: 'none',
  },
  container: {
    padding: theme.spacing(1),
  },
  containerList: {
    width: '100%',
    height: '100%',
  },
  fixedHeight: {
    position: 'absolute',
    height: '100%',
    overflowX: 'hidden',
    overlflowY: 'scroll',
  },
}))

async function removeResource(
  props,
  resource,
  enqueueSnackbar,
  setSelected,
  setOpenConfirm,
) {
  await props.model.remove(...(props.parentSelections || []), resource.id) //añadir error
  setSelected(undefined)
  await props.refreshData()

  enqueueSnackbar('Se eliminó con éxito', {
    variant: 'success',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  })
  setOpenConfirm(false)
}

const ResourceListItem = ({
  setSelected,
  resource,
  isSelected,
  modalMode,
  ...props
}) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleClick = () => {
    setSelected(resource.id)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const onDeleteClick = (e) => {
    setOpenConfirm(true)
  }

  const onDelete = () => {
    removeResource(
      props,
      resource,
      enqueueSnackbar,
      setSelected,
      setOpenConfirm,
    )
  }

  const { enqueueSnackbar } = useSnackbar()
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={openConfirm}
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {'Confirme antes de eliminar'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            ¿Está seguro que desea elimiar el recurso? Todos los datos asociados
            se eliminarán. Esta operación no se puede revertir.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)} color='primary'>
            Cancelar
          </Button>
          <Button onClick={onDelete} color='danger'>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={11}>
        <ButtonGroup
          variant='contained'
          color={isSelected ? 'primary' : 'secondary'}
          aria-label='split button'
          size='small'
          fullWidth
        >
          <Button onClick={handleClick} className={classes.resourceName}>
            {props.model.customName
              ? props.model.customName(resource)
              : resource.name}
          </Button>
          {!props.listOnly && (
            <Button
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label='select merge strategy'
              aria-haspopup='menu'
              onClick={handleToggle}
              className={classes.dropdownButton}
              ref={anchorRef}
            >
              <ArrowDropDownIcon />
            </Button>
          )}
        </ButtonGroup>
        {!props.listOnly && (
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 10 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'right top' : 'right bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id='split-button-menu'>
                      <ResourcesModalForm
                        mode='edit'
                        resource={resource}
                        buttonComponent={<MenuItem>Editar</MenuItem>}
                        resources={props.resources}
                        {...props}
                      />
                      <MenuItem onClick={onDeleteClick}>Eliminar</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </Grid>
    </React.Fragment>
  )
}

function ResourcesAsList({ resources = [], ...props }) {
  const classes = useStyles()
  if (!props.enoughSelections) {
    return null
  }

  return (
    <React.Fragment>
      <div
        className={clsx(
          props.allowCreation && classes.fixedHeight,
          classes.containerList,
        )}
      >
        <Grid
          container
          spacing={1}
          justify='center'
          className={classes.container}
        >
          {resources.map((r) => (
            <ResourceListItem
              key={r.id}
              resource={r}
              isSelected={
                _(props.selected).isArray()
                  ? _(props.selected).indexOf(r.id) > -1
                  : props.selected === r.id
              }
              {...props}
            />
          ))}
          {!props.listOnly && (
            <Grid item xs={11}>
              {props.enoughSelections ? (
                <ResourcesModalForm
                  mode='create'
                  {...props}
                  resources={resources}
                />
              ) : (
                <></>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default ResourcesAsList
