import React, { useState, useEffect } from 'react';
import { Grid, Paper, Box, Button, Typography, makeStyles, TextField, Select, MenuItem } from '@material-ui/core';
import { Farm, ProductiveUnits } from 'utils/api/models';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as actions from 'actions';

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'absolute',
        flexGrow: '3',
        flexDirection: 'column',
        marginTop: '-85px',
        right: theme.spacing(2),
        zIndex: 1, // Agregamos el z-index para que esté por encima de otros elementos
    },
    formContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[5],
        zIndex: 2, // Aseguramos que el formulario esté por encima de otros elementos
    },
    listShape: {
        backgroundColor: 'white',
        width: '90%',
        minHeight: '50px',
    },
}));

const ProductiveUnit = () => {
    const classes = useStyles()
    const [farmsData, setFarmsData] = useState([])
    const [showForm, setShowForm] = useState(false)
    const [selectedFarm, setSelectedFarm] = useState('')
    const [productiveUnitsData, setProductiveUnitsData] = useState([])
    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [unitName, setUnitName] = useState('')
    const [unitToDelete, setUnitToDelete] = useState(null)
    const routeParams = useSelector((state) => state.routeParams)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const showConfirmationMessage = (id) => {
        setUnitToDelete(id)
        setConfirmationMessage(
            <div>
                <Typography variant="body1" style={{ marginBottom: '10px' }}>¿Estás seguro de que deseas eliminar esta unidad productiva? <br />
                    Se perderán los registros asociados a esta unidad productiva</Typography>
                <Button onClick={() => handleDelete(id)} variant="contained" color="secondary">
                    Sí, eliminar
                </Button>
                <Button onClick={handleCancelDelete} variant="contained" color="default">
                    Cancelar
                </Button>
            </div>,
        )
        setShowDeleteConfirmation(true)
    }

    const handleCancelDelete = () => {
        setConfirmationMessage(null)
        setShowDeleteConfirmation(false)
        setUnitToDelete(null)
    }

    useEffect(() => {
        getFarmData()
    }, [routeParams.userId, routeParams.farmOwnerId])

    useEffect(() => {
        getProductiveUnitData()
    }, [routeParams.userId, routeParams.farmOwnerId])

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        try {
            const response = await ProductiveUnits.all()
            setProductiveUnitsData(response.data)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }


    async function getFarmData() {
        const farms = await Farm.all()
        setFarmsData(farms)
        dispatch(actions.breadcrumbResourcesUpdated({ farmOwnerId: true }))
    }

    async function getProductiveUnitData() {
        try {
            const response = await ProductiveUnits.all()
            console.log('check', response)
            const unitsData = response.data
            setProductiveUnitsData(unitsData)
        } catch (error) {
            console.error('Error fetching units:', error)
        }
    }

    const handleAddUnitClick = () => {
        setShowForm(true);
    };

    const handleFormClose = () => {
        setShowForm(false);
    };

    const handleFarmSelection = (event) => {
        const selectedFarmId = event.target.value
        setSelectedFarm(selectedFarmId)
        console.log('Selected Farm:', selectedFarmId)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            await ProductiveUnits.create({
                farm_id: selectedFarm,
                productive_unit_name: unitName,
            })
            enqueueSnackbar('Ingreso de unidad productiva exitoso', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            })
            fetchData()
            handleFormClose()
        } catch (error) {
            enqueueSnackbar('Hubo un error al ingresar los datos, verifique los campos e intente nuevamente', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
        }
    }

    const handleDelete = async (id) => {
        try {
            await ProductiveUnits.remove(id)
            fetchData()
        } catch (error) {
            console.error(`Error deleting element with ID ${id}:`, error)
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper>
                    <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        justifyContent='space-between'
                        padding='10px'
                    >
                        <h2>Administrar unidades productivas</h2>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='space-between'
                    position='relative'
                    height='80vh'
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            setSelectedFarm('');
                            handleAddUnitClick();
                        }}

                    >
                        Agregar unidad productiva
                    </Button>
                    {showForm && (
                        <form onSubmit={handleSubmit} className={classes.formContainer}>
                            <Typography variant="h6" style={{ textAlign: 'center' }}>Agregar unidad productiva</Typography>
                            <Typography variant="subtitle1" align="left">Campo:</Typography>
                            <Select
                                value={selectedFarm}
                                onChange={handleFarmSelection}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            >
                                {farmsData.map(farm => (
                                    <MenuItem key={farm.id} value={farm.id}>{farm.name}</MenuItem>
                                ))}
                            </Select>
                            <Typography variant="subtitle1" align="left">Unidad productiva:</Typography>
                            <TextField
                                variant="outlined"
                                label="Nombre"
                                fullWidth
                                margin="normal"
                                value={unitName}
                                onChange={(event) => setUnitName(event.target.value)}
                            />
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <Button variant="contained" color="primary" type="submit">
                                        Guardar
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={handleFormClose}>
                                        Cancelar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                    <Grid container spacing={3}>
                        {farmsData.map((farm) => (
                            <Grid item xs={6} key={farm.id}>
                                <Paper style={{ marginTop: '10px' }}>
                                    <Box>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="h6" style={{ height: '80px', lineHeight: '80px', width: '80%', borderBottom: '0.5px solid #CBCBCB', padding: '0 10px' }}>Unidades productivas en {farm.name}:</Typography>
                                            <div style={{ borderBottom: '0.5px solid #CBCBCB', width: '20%', height: '80px' }}></div>
                                        </div>
                                        {productiveUnitsData
                                            .filter((unit) => unit.farm_id === farm.id)
                                            .map((unit, index, array) => (
                                                <Grid container spacing={3} key={unit.id} style={{ marginBottom: '5px' }}>
                                                    <Grid item xs={12}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0', borderBottom: index !== array.length - 1 ? '0.5px solid #CBCBCB' : 'none' }}>
                                                            <Typography
                                                                style={{ height: '40px', lineHeight: '40px', paddingLeft: '10px', textAlign: 'left' }}
                                                            >{unit.productive_unit_name}</Typography>
                                                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '15px', marginBottom: '5px', ...(index === 0 ? { marginTop: '10px' } : { marginTop: '-10px' }) }}>
                                                                {unit.id === unitToDelete ? (
                                                                    <Paper>
                                                                        <Box p={2}>
                                                                            {confirmationMessage}
                                                                        </Box>
                                                                    </Paper>
                                                                ) : (
                                                                    <Button variant="contained" onClick={() => showConfirmationMessage(unit.id)}>
                                                                        Eliminar
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        {productiveUnitsData.filter((unit) => unit.farm_id === farm.id).length === 0 && (
                                            <Typography style={{ padding: '10px', textAlign: 'center' }}>
                                                No hay unidades productivas asociadas.
                                            </Typography>
                                        )}
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ProductiveUnit;
